// extracted by mini-css-extract-plugin
export var awardCard = "cZ_ww";
export var awardCard__bdg_large = "cZ_wB";
export var awardCard__bdg_small = "cZ_wC";
export var awardCard__container = "cZ_wJ";
export var awardCard__description = "cZ_wH";
export var awardCard__info = "cZ_wD";
export var awardCard__large = "cZ_wy";
export var awardCard__link = "cZ_wG";
export var awardCard__post = "cZ_wx";
export var awardCard__small = "cZ_wz";
export var awardCard__title = "cZ_wF";